'use strict';

/*******************************************************************************************/
class UxrEeTextline extends React.Component {
	constructor(props) {
		super(props);
		this.defaultValue = '';
		this.state = {
			'value': this.props.value || this.props.defaultValue || this.defaultValue,
		};
		
		this.onChange = this.onChange.bind(this);
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		return (
			<div className="MVT-ctrl=MVT_terminal=FformLine" {...this.props.htmlProps}>
				<input type="text" value={this.state.value} placeholder={this.props.placeholder} onChange={this.onChange} />
			</div>
		);
	}
	
	/*---------------------------------------------------------------------*/
	onChange(event) {
		let value = event.target.value;
		this.setState({
			'value': value
		})
		this.props.onChange(this, {value});
	}
	
} //class

/*******************************************************************************************/
class UxrEeTextarea extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			'value': this.props.value
		};
		
		this.onChange = this.onChange.bind(this);
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		return (
			<div className="MVTctrl=MVT_terminal=FformLine" {...this.props.htmlProps}>
				<UxrEe_.TextareaAutosize.default placeholder={this.props.placeholder} value={this.state.value} onChange={this.onChange} rows={this.props.minRows} maxRows={this.props.maxRows} />
			</div>
		);
	}
	
	/*---------------------------------------------------------------------*/
	onChange(event) {
		let value = event.target.value;
		this.setState({
			'value': value
		})
		this.props.onChange(this, {value});
	}
	
} //class
